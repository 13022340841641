import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CanceledAtom, CancellationAtom, useSignature } from '../lib/state.ts';
import Header from '../Header/Header.tsx';
import { cancelReservationFinish } from '../../../lib/api.ts';
import { ApiError } from '../../../lib/ApiError.ts';

import styles from './Confirm.module.css';

const Confirm: React.FC = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [canceling, setCanceling] = useState<boolean>(false);
  const cancellation = useAtomValue(CancellationAtom);
  const setCancelFinish = useSetAtom(CanceledAtom);
  const signature = useSignature(uuid!);

  const onNext = () => {
    if (!signature) {
      return;
    }

    if (!uuid) {
      return;
    }

    const go = async () => {
      if (!cancellation) {
        return;
      }

      if (!uuid) {
        return;
      }

      setCanceling(true);

      try {
        const response = await cancelReservationFinish(signature, uuid, {
          type: cancellation.type,
        });

        if (response) {
          setCancelFinish(response);
          navigate(`../canceled/`);
        }
      } catch (e: unknown) {
        if (
          e instanceof ApiError &&
          e.type === 'AlreadyCanceledReservationErrorResponse'
        ) {
          navigate(`../canceled/`);
        } else if (e instanceof Error) {
          console.error('ERROR', e.message);
          Sentry.captureException(e);
        }
      } finally {
        setCanceling(false);
      }
    };

    void go();
  };

  if (!cancellation) {
    return (
      <div>
        <Header title="Sorry, cannot find reservation" />
      </div>
    );
  }

  const reservation_at = dayjs(cancellation.reservation.reservation_at);

  return (
    <>
      <Header title="Are you sure you would like to cancel your reservation?" />

      <div className="content">
        {cancellation && <h2>{cancellation.reservation.restaurant?.name}</h2>}

        <div className="section">
          <div>
            {reservation_at
              .tz(cancellation.reservation.restaurant.timezone)
              .format('ddd, MMMM D')}
          </div>
          <div>{cancellation.reservation.reservation_time_pretty}</div>
          <div>{cancellation.reservation.party_size} guests</div>
          <div>{cancellation.reservation.table_type}</div>

          <div className={styles.minimum}>
            {cancellation.reservation.table_minimum__currency} total minimum
          </div>
          <div className={styles.minimum_pp}>
            {cancellation.reservation.price_per_person__currency} minimum per
            person
          </div>
        </div>

        <div className={clsx('section', styles.policy)}>
          <h3>Cancellation policy</h3>

          <p>
            Reservations are bookable 30 days in advance and can be canceled up
            to 8 hours before the start time for a refund minus a 10% processing
            fee. Any cancellations made within 8 hours of the reservation time
            are non-refundable. Once a reservation is booked, the minimum spend
            per person remains the same even if the party size decreases.
            Requests to increase a party size can be made to info@dorsia.com,
            but are not guaranteed.
          </p>

          <button className="nextButton" onClick={onNext} disabled={canceling}>
            Yes, I would like to cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default Confirm;
